<template>
  <div :class="size == 'large' ? 'large-box' : ''">
    <commonHeader> </commonHeader>
    <div class="center-box" :class="size == 'large' ? 'large' : ''">
      <slot></slot>
    </div>
    <commonFooter></commonFooter>
  </div>
</template>

<script>
import CommonHeader from './commonHeader'
import CommonFooter from './commonFooter'
export default {
  components: {
    CommonHeader,
    CommonFooter
  },
  props: ['size'],
  name: 'layoutContainer',
  mounted() {
    // $(window).scrollTop(0)
  }
}
</script>

<style lang="scss" scoped>
.center-box {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
}

.large-box {
  background: #fff;
}

.large {
  width: 100%;
  margin-top: 0;
  margin-bottom: 25px;
}
</style>
