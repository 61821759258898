import axios from 'axios'
import store from '@/store'
import Vue from 'vue'
// 根据环境不同引入不同api地址
const baseApi = 'https://www.quanqingkeji.com/api'
// const baseApi = 'http://81.70.36.99:8001'
// const isProd = process.env.NODE_ENV !== 'development'
// const baseApi = isProd ? 'http://81.70.36.99:8001' : 'api'
// const baseApi = isProd ? 'api' : 'api'

// create an axios instance
const service = axios.create({
  baseURL: baseApi, // url = base api url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000000000 // request timeout
})
const Vm = new Vue()
// request 拦截器 request interceptor
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    config.headers['token'] = localStorage.getItem('TOKEN')
    // 不传递默认开启loading
    if (!config.hideloading) {
      // loading
      // Toast.loading({
      //   forbidClick: true
      // })
    }
    if (store.getters.token) {
      config.headers['token'] = localStorage.getItem('TOKEN')
    } else {
      // config.headers['token'] = 'asdfasdfasdfasd'
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// respone拦截器
service.interceptors.response.use(
  response => {
    // Toast.clear()
    const res = response.data
    const {url} = response?.config
    if (url.indexOf('/user/getUserInfo/' != -1) && res.code == 200007) {
      localStorage.setItem('UserInfo', JSON.stringify({}))
      store.commit('ChangeUserInfo', {})
    }
    if (res.code == 200007) {
      // alert(res.msg)
      location.assign(location.origin + '/#/login')
      // location.assign('http://59.110.164.213/#/login')
      return
    }
    if (res.code != 200) {
      // Vm.$message(res.msg)
      res.data = res.msg
      return Promise.resolve(res)
    }
    if (res.status && res.status !== 200) {
      // 登录超时,重新登录
      if (res.status === 401) {
        // store.dispatch('FedLogOut').then(() => {
        //   location.reload()
        // })
      }
      return Promise.reject(res || 'error')
    } else {
      return Promise.resolve(res)
    }
  },
  error => {
    return Promise.reject(error)
  }
)
export default service
